
.mini-Decks {
  display: grid;
  justify-content: center;
}

.mini-Decks .title {
  text-transform: capitalize;
}

.mini-Decks .deck {
  align-self: center;
}

.mini-Decks .title {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 36px;
  min-width: 36px;
}

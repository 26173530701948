.mini-Sites {
  display: grid;
  justify-content: center;
}

.mini-Sites .title {
  text-transform: capitalize;
}

.mini-Sites .sites {
  align-self: center;
}

.mini-Sites .title {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 40px;
  min-width: 33px;
}

.mini-Sites .title.rubble {
  background-image: url("../card/images/ver2/rubble-site.png");
}
.mini-Sites .title.wood {
  background-image: url("../card/images/ver2/wood-site.png");
}
.mini-Sites .title.concrete {
  background-image: url("../card/images/ver2/concrete-site.png");
}
.mini-Sites .title.brick {
  background-image: url("../card/images/ver2/brick-site.png");
}
.mini-Sites .title.stone {
  background-image: url("../card/images/ver2/stone-site.png");
}
.mini-Sites .title.marble {
  background-image: url("../card/images/ver2/marble-site.png");
}

.mini-Clientele {
  display: grid;
}

.mini-Clientele .tag {
  min-width: 1.3em;
  min-height: 7em;
  background-size: cover;
  background-repeat: no-repeat;
}

.mini-Clientele .tag:not(.last) {
  min-width: 1.9em;
  margin-right: -0.6em;
}

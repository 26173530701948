.PlayLayout {
  display: grid;
  font-size: calc(10px + 1vmin);
  color: white;
  position: relative;
}

.PlayLayout .sidebar {
  display: flex;
  align-items: stretch;
  justify-items: stretch;
}

/* Horizontal layout */
@media not all and (max-width: 1200px) {
  .PlayLayout {
    height: 100vh;
    grid-template-columns: 200px auto 265px;
  }
  .PlayLayout:not(.observe) {
    grid-template-rows: 100px auto 120px;
    grid-template-areas:
      "title title title"
      "players mat log"
      "options options options";
  }
  .PlayLayout.observe {
    grid-template-rows: 100px auto;
    grid-template-areas:
      "title title title"
      "players mat log";
  }
  .PlayLayout .LogPanel .log {
    width: 265px;
  }
  .PlayLayout .sidebar {
    flex-direction: column;
  }
}

/* Vertical layout */
@media only all and (max-width: 1200px) {
  .PlayLayout {
    width: 100vw;
    /* grid-template-columns: 200px auto 265px; */
    grid-template-rows: auto auto auto 200px 120px;
    grid-template-areas:
      "title"
      "mat"
      "players"
      "log"
      "options";
  }

  .PlayLayout .LogPanel .log {
    width: 100%;
  }

  .PlayLayout .sidebar {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

/* Tall enough vertical layout */
@media only all and (max-width: 1200px) and (min-height: 600px) {
  .PlayLayout .sidebar {
    position: sticky;
    z-index: 6;
  }
  .PlayLayout.observe .sidebar {
    bottom: 0;
  }
  .PlayLayout:not(.observe) .sidebar {
    bottom: 120px;
  }
  .PlayLayout .ControlPanel {
    position: sticky;
    bottom: 0;
    z-index: 5;
  }
}

.PlayLayout .App-header {
  grid-area: title;
}

.PlayLayout .sidebar {
  grid-area: players;
  overflow-y: auto;
}

.PlayLayout .ControlPanel {
  grid-area: options;
}

.PlayLayout .LogPanel {
  grid-area: log;
}

.PlayLayout .game {
  grid-area: mat;
  overflow: auto;
}

.PlayLayout .options {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.PlayLayout .options > .option {
  margin: 5px;
  font-size: 20px;
}

.PlayLayout .options > .option.laborer,
.PlayLayout .options > .option.rubble {
  background-color: hsl(49deg 99% 30%);
  color: hsl(0deg 0% 97%);
  border: hsl(49deg 99% 38%) outset 2px;
}

.PlayLayout .options > .option.craftsman,
.PlayLayout .options > .option.wood {
  background-color: hsl(9deg 72% 24%);
  color: hsl(0deg 0% 87%);
  border: hsl(9deg 68% 34%) outset 2px;
}

.PlayLayout .options > .option.legionary,
.PlayLayout .options > .option.brick {
  background-color: hsl(8deg 86% 34%);
  color: hsl(0deg 0% 88%);
  border: hsl(8deg 86% 43%) outset 2px;
}

.PlayLayout .options > .option.architect,
.PlayLayout .options > .option.concrete {
  background-color: hsl(312deg 3% 31%);
  color: hsl(0deg 0% 89%);
  border: hsl(312deg 3% 38%) outset 2px;
}

.PlayLayout .options > .option.merchant,
.PlayLayout .options > .option.stone {
  background-color: hsl(194deg 100% 31%);
  color: hsl(0deg 0% 94%);
  border: hsl(194deg 100% 38%) outset 2px;
}

.PlayLayout .options > .option.patron,
.PlayLayout .options > .option.marble {
  background-color: hsl(313deg 53% 36%);
  color: hsl(0deg 0% 88%);
  border: hsl(313deg 53% 43%) outset 2px;
}

.PlayLayout .options > .option.jack {
  background-color: hsl(39deg 79% 38%);
  color: hsl(0deg 0% 93%);
  border: hsl(39deg 79% 43%) outset 2px;
}

.PlayLayout .error {
  color: red;
}

.PlayLayout .LogPanel {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

/* Options pop-ups */

.PlayLayout .option-popup {
  text-align: center;
}

.PlayLayout .title {
  font-weight: bold;
  margin: 3px;
}

.PlayLayout .description {
  margin-bottom: 10px;
}

.PlayLayout .option-popup div {
  margin: 2px;
}

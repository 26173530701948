.mini-ClosedHand {
  display: grid;
}

.mini-ClosedHand .tag {
  min-width: 2.3em;
  min-height: 7em;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 0.3em;
  margin: 0.1em;
}

.view-tag {
  background: darkslategray;
  padding: 5px;
  border-radius: 5px;
  border: solid transparent;
  margin: 10px 10px 10px 10px;
  position: relative;
}

/* Horizontal layout */
@media not all and (max-width: 1200px) {
  .view-tag.selected::after,
  .view-tag.defaultSelect::after {
    content: ">";
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    padding-left: 12px;
    position: absolute;
    font-weight: normal;
  }
}

/* Vertical layout */
@media only all and (max-width: 1200px) {
  .view-tag.selected::after,
  .view-tag.defaultSelect::after {
    content: "∆";
    left: 50%;
    top: 0%;
    transform: translateX(-50%) translateY(-100%);
    padding-bottom: 5px;
    position: absolute;
    font-weight: normal;
  }
}

.view-tag.selected::after  {
  color: white;
}

.view-tag.defaultSelect:not(.selected)::after {
  color: grey;
}

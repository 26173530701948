.tag-wrapper {
  display: flex;
  align-items: stretch;
  justify-items: stretch;
  justify-content: stretch;
  align-content: stretch;
  padding-right: 10px;
}

.tag-wrapper > * {
  width: 100%;
}

.sidebar .role {
  min-width: 150px;
  padding: 5px;
  margin-top: 10px;
}

/* Vertical layout */
@media only all and (max-width: 1200px) and (min-height: 600px) {
  .tag-wrapper {
    min-width: 150px;
    padding-top: 5px;
  }
  .sidebar {
    padding-top: 10px;
  }
}
/* Horizontal layout */
@media not all and (max-width: 1200px) {
  .tag-wrapper {
    padding-right: 12px;
  }
}

.card {
  display: inline-block;
  color: black;
  position: relative;
  background-color: white;
  margin: 3px;
  padding: 3px;
}
.card,
.card.ver2.player-visibility::after {
  border-radius: 3px;
  cursor: pointer;
}
.card .effects {
  display: none;
}
.card.with-overlay .effects {
  position: absolute;
  top: 57%;
  left: 18%;
  right: 6%;
  bottom: 5%;
  color: black;
  font-size: 12px;
  font-family: serif;
  display: flex;
  flex-direction: column;
  justify-content: top;
  margin: 5px;
  border-radius: 4px;
  box-shadow: 0 0 5px white, 0 0 5px white;
  background-color: rgba(211, 211, 211, 1);
  overflow-y: hidden;
  user-select: none;
}
.card.with-overlay .effects .type {
  font-weight: bold;
}
.card.with-overlay:hover .effects {
  top: 7%;
  left: 2%;
  right: 2%;
  bottom: 2%;
  justify-content: center;
}
.card.empty {
  color: rgba(255, 255, 255, 0.4);
  background-color: rgba(0, 0, 0, 0.15);
}

.card.jack {
  background-color: white;
}

.card.rubble,
.card.laborer {
  background-color: #feff05;
}

.card.wood,
.card.craftsman {
  background-color: #deb887;
}

.card.concrete,
.card.architect {
  background-color: #788899;
}

.card.brick,
.card.legionary {
  background-color: #fe6247;
}

.card.stone,
.card.merchant {
  background-color: #426ae1;
}

.card.marble,
.card.patron {
  background-color: #dd9fdc;
}

.card.iron,
.card.engineer {
  background-color: #64d4fd;
}

.card.wheat,
.card.farmer {
  background-color: #2cc18e;
}

.card-popup {
  text-align: center;
}

.card-popup .name {
  font-weight: bold;
  margin: 3px;
}

.card-popup .effects {
  margin-bottom: 10px;
}

.card-popup div {
  margin: 2px;
}

.card.ver2:not(.empty),
.card.ver2.player-visibility::after {
  max-width: 127px;
  width: 127px;
  height: 176px;
  color: transparent;
  background-color: transparent;
  padding: 0;
  margin: 0;
  text-overflow: clip;
  background-repeat: no-repeat;
}
.card.ver2.player-visibility::after {
  background-image: url("./card/images/ver2/order.png");
  background-size: cover;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.card.ver2.player-visibility:hover::after {
  visibility: hidden;
}
.card.ver2.academy {
  background-image: url("./card/images/ver2/academy.png");
}
.card.ver2.amphitheatre {
  background-image: url("./card/images/ver2/amphitheatre.png");
}
.card.ver2.aqueduct {
  background-image: url("./card/images/ver2/aqueduct.png");
}
.card.ver2.archway {
  background-image: url("./card/images/ver2/archway.png");
}
.card.ver2.atrium {
  background-image: url("./card/images/ver2/atrium.png");
}
.card.ver2.bar {
  background-image: url("./card/images/ver2/bar.png");
}
.card.ver2.basilica {
  background-image: url("./card/images/ver2/basilica.png");
}
.card.ver2.basilica-arbitrium {
  background-image: url("./card/images/ver2/basilica-arbitrium.png");
}
.card.ver2.bath {
  background-image: url("./card/images/ver2/bath.png");
}
.card.ver2.brick.site {
  background-image: url("./card/images/ver2/brick-site.png");
}
.card.ver2.bridge {
  background-image: url("./card/images/ver2/bridge.png");
}
.card.ver2.catacomb {
  background-image: url("./card/images/ver2/catacomb.png");
}
.card.ver2.circus {
  background-image: url("./card/images/ver2/circus.png");
}
.card.ver2.circus-maximus {
  background-image: url("./card/images/ver2/circus-maximus.png");
}
.card.ver2.coliseum {
  background-image: url("./card/images/ver2/coliseum.png");
}
.card.ver2.concrete.site {
  background-image: url("./card/images/ver2/concrete-site.png");
}
.card.ver2.crane {
  background-image: url("./card/images/ver2/crane.png");
}
.card.ver2.dock {
  background-image: url("./card/images/ver2/dock.png");
}
.card.ver2.domus-aurea {
  background-image: url("./card/images/ver2/domus-aurea.png");
}
.card.ver2.forum {
  background-image: url("./card/images/ver2/forum.png");
}
.card.ver2.forum-romanum {
  background-image: url("./card/images/ver2/forum-romanum.png");
}
.card.ver2.foundry {
  background-image: url("./card/images/ver2/foundry.png");
}
.card.ver2.fountain {
  background-image: url("./card/images/ver2/fountain.png");
}
.card.ver2.garden {
  background-image: url("./card/images/ver2/garden.png");
}
.card.ver2.gate {
  background-image: url("./card/images/ver2/gate.png");
}
.card.ver2.insula {
  background-image: url("./card/images/ver2/insula.png");
}
.card.ver2.jack {
  background-image: url("./card/images/ver2/jack0.png");
}
.card.ver2.latrine {
  background-image: url("./card/images/ver2/latrine.png");
}
.card.ver2.ludus-magna {
  background-image: url("./card/images/ver2/ludus-magna.png");
}
.card.ver2.marble.site {
  background-image: url("./card/images/ver2/marble-site.png");
}
.card.ver2.market {
  background-image: url("./card/images/ver2/market.png");
}
.card.ver2.order {
  background-image: url("./card/images/ver2/order.png");
  background-size: cover;
}
.card.ver2.palace {
  background-image: url("./card/images/ver2/palace.png");
}
.card.ver2.palisade {
  background-image: url("./card/images/ver2/palisade.png");
}
.card.ver2.prison {
  background-image: url("./card/images/ver2/prison.png");
}
.card.ver2.road {
  background-image: url("./card/images/ver2/road.png");
}
.card.ver2.rubble.site {
  background-image: url("./card/images/ver2/rubble-site.png");
}
.card.ver2.school {
  background-image: url("./card/images/ver2/school.png");
}
.card.ver2.scriptorium {
  background-image: url("./card/images/ver2/scriptorium.png");
}
.card.ver2.senate {
  background-image: url("./card/images/ver2/senate.png");
}
.card.ver2.sewer {
  background-image: url("./card/images/ver2/sewer.png");
}
.card.ver2.shrine {
  background-image: url("./card/images/ver2/shrine.png");
}
.card.ver2.stairway {
  background-image: url("./card/images/ver2/stairway.png");
}
.card.ver2.statue {
  background-image: url("./card/images/ver2/statue.png");
}
.card.ver2.stone.site {
  background-image: url("./card/images/ver2/stone-site.png");
}
.card.ver2.storeroom {
  background-image: url("./card/images/ver2/storeroom.png");
}
.card.ver2.temple {
  background-image: url("./card/images/ver2/temple.png");
}
.card.ver2.tower {
  background-image: url("./card/images/ver2/tower.png");
}
.card.ver2.tribunal {
  background-image: url("./card/images/ver2/tribunal.png");
}
.card.ver2.villa {
  background-image: url("./card/images/ver2/villa.png");
}
.card.ver2.vomitorium {
  background-image: url("./card/images/ver2/vomitorium.png");
}
.card.ver2.wall {
  background-image: url("./card/images/ver2/wall.png");
}
.card.ver2.wood.site {
  background-image: url("./card/images/ver2/wood-site.png");
}

.mini-Vault {
  display: grid;
}

.mini-Vault .tag {
  min-width: 1.3em;
  min-height: 7em;
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
}

.mini-Vault .tag:not(.first) {
  min-width: 1.9em;
  margin-left: -0.6em;
}

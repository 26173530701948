
.game {
  flex-flow: column wrap;
  width: 100%;
}
.game .sites {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 10px;
  padding: 5px;
  justify-content: center;
  max-width: calc(300px * 3 + 100px);
  margin: 0 auto;
}
.game .sites > .site {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
  margin: 0 auto;
  box-sizing: border-box;
}
.game .sites > .site .card.ver2:not(:first-child) {
  margin-right: -100px;
}
.game .players {
  width: 100%;
  position: relative;
}
.game .view-area:not(.top) {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.game .view-area.top:not(.selected) {
  opacity: 0;
}
.game .view-area:not(.selected):not(.top) {
  display: none;
}
.game .view-area.selected {
  z-index: 2;
}
.game .view-area {
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
}
.game .pool {
  width: 100%;
}
.game .pool .cards {
  flex-wrap: nowrap;
}
.game .pool .card.ver2 {
  flex-basis: 50px;
  flex-grow: 1;
}
.game .pool .card.ver2:last-child {
  flex-basis: 127px;
  flex-shrink: 0;
  flex-grow: 0;
}
.game .pool .card.ver2:not(:first-child) {
  flex-shrink: 1;
}

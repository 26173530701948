.avatar.laborer {
  background-image: url("avatar-laborer.png"), radial-gradient(
    circle at center,
    white,
    transparent 75%
  );
}
.avatar.craftsman {
  background-image: url("avatar-craftsman.png"), radial-gradient(
    circle at center,
    white,
    transparent 75%
  );
}
.avatar.legionary {
  background-image: url("avatar-legionary.png"), radial-gradient(
    circle at center,
    white,
    transparent 75%
  );
}
.avatar.architect {
  background-image: url("avatar-architect.png"), radial-gradient(
    circle at center,
    white,
    transparent 75%
  );
}
.avatar.merchant {
  background-image: url("avatar-merchant.png"), radial-gradient(
    circle at center,
    white,
    transparent 75%
  );
}
.avatar.patron {
  background-image: url("avatar-patron.png"), radial-gradient(
    circle at center,
    white,
    transparent 75%
  );
}
.avatar {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

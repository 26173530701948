.mini-Player {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
  border-radius: 0.5em;
  background: #2f4f4f78;
  justify-content: space-between;
}

.mini-Player.self {
  background: rgb(58 102 146 / 34%);
}

.mini-Player > .name {
  position: relative;
  min-width: 100px;
}

.mini-Player > .name > .details {
  position: absolute;
  top: 100%;
  left: 0;
}

.mini-Player > .score {
  min-width: 60px;
}

.mini-Player > .name > * {
  width: 100%;
}

.mini-Player .clientele-hand-vault {
  display: flex;
  justify-content: center;
  gap: 0.5em;
  width: 100%;
}

.mini-Player .clientele-hand-vault .mini-ClosedHand {
  background-color: rgba(0, 0, 0, 0.1);
}

.mini-Player .mini-Action {
  position: absolute;
  top: 0;
  left: 15%;
}

.mini-Player .mini-RomeDemands {
  position: absolute;
  top: 0;
  right: 15%;
}

.mini-Player .mini-Stockpile {
  width: 100%;
}

.mini-Player .clientele-label {
  flex-basis: 30%;
}

.mini-Player .stockpile-label {
  flex-basis: 30%;
}

.mini-Player .vault-label {
  flex-basis: 30%;
}

.mini-Player.rubble {
  background-color: yellow;
}

.mini-Player.wood {
  background-color: burlywood;
}

.mini-Player.concrete {
  background-color: lightslategrey;
}

.mini-Player.brick {
  background-color: tomato;
}

.mini-Player.stone {
  background-color: royalblue;
}

.mini-Player.marble {
  background-color: plum;
}

.game-over {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5%;
  background-color: black;
  opacity: 0.85;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  border-radius: 10px;
  min-width: 40%;
  z-index: 10;
}
.game-over button.close {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background-color: transparent;
  color: white;
  font-size: 20px;
  cursor: pointer;
}
.game-over a {
  color: inherit;
  text-decoration: none;
}
.game-over .scores {
  flex-grow: 1;
}
.game-over .scores tr:not(:last-child) td,
.game-over .scores tr th {
  padding-bottom: 5px;
  border-bottom: 1px solid grey;
}

.contact {
  display: flex;
  flex-direction: column;
}
.contact .success {
  color: green;
}
.contact .failure {
  color: red;
}
.contact .form-wrapper {
  display: flex;
}
.contact .form {
  max-width: 400px;
  margin: 0 auto;
}
.contact .form input, .contact .form textarea {
  width: 400px;
  margin-bottom: 10px;
  border: 0;
  padding: 5px;
  font-family: Arial;
}
.contact .form textarea {
  height: 200px;
}

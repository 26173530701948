.mini-Stockpile {
  display: grid;
  justify-content: center;
  gap: 0.1em;
}

.mini-Stockpile .tag {
  min-width: 4.2em;
  min-height: 0.9em;
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}

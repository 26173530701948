.App {
  background: #050505 url("background.png");
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App button {
  cursor: pointer;
}

.App .main {
  flex-grow: 1;
  display: flex;
  color: white;
   background: #00000080;
}

.App .main > * {
  flex-grow: 1;
}

.App .footer {
  padding: 20px;
  color: #bdbdbd;
  font-size: 0.8em;
  background: #000000c0;
}

.App .footer .copyright-notice {
  max-width: 675px;
  margin: 10px auto 0 auto;
}

.App a .envelope {
  color: #bdbdbd;
  width: 1em;
  vertical-align: -1.6px;
}

.App .envelope-icon {
  margin-left: 5px;
}

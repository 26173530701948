.pool-tag .pool {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2px;
}
.pool-tag .pool .count,
.pool-tag .pool .one {
  width: 12px;
  height: 20px;
  font-size: 16px;
  border: black solid 1px;
  border-radius: 2px;
  overflow: clip;
  font-weight: bolder;
}
.pool-tag .pool .count .digit {
  position: relative;
  top: 1px;
  color: black;
}

.pool-tag .sites {
  margin-top: 3px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2px;
}
.pool-tag .sites .site {
  position: relative;
  height: 30px;
}
.pool-tag .sites .in-town.count.empty {
  visibility: hidden;
}
.pool-tag .sites .in-town.count {
  width: 12px;
  height: 20px;
  font-size: 16px;
  border: black solid 1px;
  border-radius: 2px;
  overflow: clip;
  font-weight: bolder;
  position: relative;
}
.pool-tag .sites .in-town.count .digit {
  position: relative;
  top: 1px;
  color: black;
}
.pool-tag .sites .out-of-town.count.empty {
  visibility: hidden;
}
.pool-tag .sites .out-of-town.count {
  filter: brightness(0.7);
  width: 12px;
  height: 20px;
  font-size: 8px;
  border: black solid 1px;
  border-radius: 2px;
  overflow: clip;
  font-weight: bolder;
  position: absolute;
  top: 10px;
}
.pool-tag .sites .out-of-town.count .digit {
  position: relative;
  top: 10px;
  color: black;
}
.pool-tag .sites .out-of-town.count.fully-visible {
  font-size: 16px;
}
.pool-tag .sites .out-of-town.count.fully-visible .digit {
  top: 1px;
}

.top-players-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.top-players a {
  color: inherit;
  text-decoration: none;
}

.top-players .filters {
  margin: 15px;
}
.top-players .filters label:not(:last-child) {
  margin-right: 10px;
}
.top-players table tbody {
  font-size: larger;
}

.top-players table th {
  color: rgb(172, 172, 223);
  font-weight: normal;
}

.top-players table th,
.top-players table td {
  padding: 2px 5px;
}

.top-players table td.rating.provisional {
  opacity: 0.7;
}

.top-players table td.rating.provisional .label {
  color: rgb(172, 172, 223);
  font-weight: normal;
}
.top-players th.player-name {
  text-align: left;
  padding-left: 3em;
}
.top-players td.player-name {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-content: center;
  align-items: center;
  margin: 5px;
  gap: 5px;
}
.top-players td.player-name .avatar {
  width: 1.75em;
  height: 1.75em;
}

.lobby {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.lobby a {
  color: white;
}

.lobby .login {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}

.lobby .google-sign-in {
  display: flex;
  gap: 0.2em;
}

.lobby > .top-players-tray {
  background-color: #00000090;
}
.lobby > .players-tray,
.lobby .rooms-tray {
  background: radial-gradient(
    circle at center,
    #000000b0,
    transparent 75%
  );
}
.lobby > .top-players-tray {
  padding-left: 2em;
  padding-right: 2em;
  border-radius: 20px;
  margin-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
}
.lobby > .players-tray {
  margin-left: 10%;
  margin-right: 10%;
  border-radius: 20px;
  margin-top: 20px;
  padding-bottom: 20px;
}
.lobby > .players-tray .players {
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: center;
}
.lobby > .players-tray .players > .player {
  display: flex;
  flex-direction: column;
  gap: 3px;
  justify-content: center;
  align-items: center;
}
.lobby > .players-tray .players > .player > .avatar {
  width: 50px;
  height: 50px;
}

.lobby .games-tray {
  background: #000000b0;
}
.lobby .rooms-tray,
.lobby .games-tray {
  margin-left: 10%;
  margin-right: 10%;
  border-radius: 20px;
  margin-top: 20px;
  padding-bottom: 20px;
  overflow-y: auto;
  flex-grow: 1;
}

.lobby .rooms-tray .rooms,
.lobby .games-tray .past-games,
.lobby .games-tray .recent-games {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.lobby .rooms-tray .rooms .room,
.lobby .games-tray .past-games .past-game,
.lobby .games-tray .recent-games .recent-game {
  background: #ffffff10;
  padding: 10px;
  margin: 10px;
}

.lobby .rooms-tray .rooms .room .settings {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.lobby .rooms-tray .rooms .room .settings .radio-options {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
}

.lobby .label {
  color: rgb(172, 172, 223);
}
/*
.value {
  font-weight: bold;
} */

.lobby .buttons {
  padding: 10px;
  display: flex;
  gap: 5px;
  justify-content: center;
}

.lobby .join-us-on-discord {
  margin-top: 20px;
}

.lobby .discord {
  background-image: url("Discord_logo_2021.png");
  background-size: contain;
  display: inline-block;
  width: 138px;
  height: 26px;
  background-repeat: no-repeat;
  background-position: center;
  color: transparent;
  line-height: 25px;
}

.lobby .play-as-guest {
  background: none;
  border: none;
  color: rgb(172, 172, 223);
  font-size: medium;
}

.lobby #support-me {
  margin-top: 15px;
}
.lobby #support-me img.kofiimg {
  display: initial !important;
  vertical-align: middle;
  height: 13px !important;
  width: 20px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border: none;
  margin-top: 0;
  margin-right: 5px !important;
  margin-left: 0 !important;
  margin-bottom: 3px !important;
  content: url("https://storage.ko-fi.com/cdn/cup-border.png");
}
.lobby #support-me .kofiimg:after {
  vertical-align: middle;
  height: 25px;
  padding-top: 0;
  padding-bottom: 0;
  border: none;
  margin-top: 0;
  margin-right: 6px;
  margin-left: 0;
  margin-bottom: 4px !important;
  content: url("https://storage.ko-fi.com/cdn/whitelogo.svg");
}
.lobby #support-me {
  display: inline-block !important;
  white-space: nowrap;
  min-width: 160px;
}
.lobby #support-me span.kofitext {
  color: #fff !important;
  letter-spacing: -0.15px !important;
  vertical-align: middle;
  line-height: 33px !important;
  padding: 0;
  text-align: center;
  text-decoration: none !important;
  text-shadow: 0 1px 1px rgba(34, 34, 34, 0.05);
}
.lobby #support-me .kofitext a {
  color: #fff !important;
  text-decoration: none !important;
}
.lobby #support-me .kofitext a:hover {
  color: #fff !important;
  text-decoration: none;
}
.lobby #support-me a.kofi-button {
  box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.2);
  line-height: 36px !important;
  min-width: 150px;
  display: inline-block !important;
  background-color: rgb(129, 129, 164);
  padding: 2px 12px !important;
  text-align: center !important;
  border-radius: 7px;
  color: #fff;
  cursor: pointer;
  overflow-wrap: break-word;
  vertical-align: middle;
  border: 0 none #fff !important;
  font-family: "Quicksand", Helvetica, Century Gothic, sans-serif !important;
  text-decoration: none;
  text-shadow: none;
  font-weight: 700 !important;
  font-size: 14px !important;
}
.lobby #support-me a.kofi-button:visited {
  color: #fff !important;
  text-decoration: none !important;
}
.lobby #support-me a.kofi-button:hover {
  opacity: 0.85;
  color: #f5f5f5 !important;
  text-decoration: none !important;
}
.lobby #support-me a.kofi-button:active {
  color: #f5f5f5 !important;
  text-decoration: none !important;
}
.lobby #support-me .kofitext img.kofiimg {
  height: 15px !important;
  width: 22px !important;
  display: initial;
  animation: kofi-wiggle 3s infinite;
}
@keyframes kofi-wiggle {
  0% {
    transform: rotate(0) scale(1);
  }
  60% {
    transform: rotate(0) scale(1);
  }
  75% {
    transform: rotate(0) scale(1.12);
  }
  80% {
    transform: rotate(0) scale(1.1);
  }
  84% {
    transform: rotate(-10deg) scale(1.1);
  }
  88% {
    transform: rotate(10deg) scale(1.1);
  }
  92% {
    transform: rotate(-10deg) scale(1.1);
  }
  96% {
    transform: rotate(10deg) scale(1.1);
  }
  100% {
    transform: rotate(0) scale(1);
  }
}


.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
}

#home-link {
  text-decoration: unset !important;
  color: unset !important;
}

#right-links {
  position: absolute;
  right: 30px;
  top: 10px;
  padding: 5px;
  text-align: right;
}

#right-links a {
  color: white !important;
}

#right-links > div {
  margin-bottom: 4px;
}

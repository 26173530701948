.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-shrink: 1;
}
.cards.empty {
  min-height: 176px;
}
.card.out-of-town.site {
  filter: brightness(0.6);
  transform: rotate(180deg);
}

.mini-CompletedBuildings {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.mini-CompletedBuildings .tag {
  width: 7.5em;
  height: 1em;
  background-size: cover;
  background-repeat: no-repeat;
}

.mini-CompletedBuildings .tag:not(.last) {
  height: 1.6em;
  margin-bottom: -0.6em;
}

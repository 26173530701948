.user {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.user .header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}
.user .header .name {
  font-size: 1.5em;
  margin-block-start: 0.3em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
.user .header .label {
  color: rgb(172, 172, 223);
  font-weight: normal;
  font-size: 70%;
}

.user a {
  color: inherit;
  text-decoration: none;
}

.user .rating {
  margin-bottom: 5px;
}
.user .rating .value {
  font-size: 23px;
  padding-left: 5px;
}
.user .rating.provisional .value {
  opacity: 0.8;
}
.user .rating:not(.provisional) .value {
  font-weight: bold;
}

.user .stats > div {
  margin-top: 4px;
  margin-bottom: 4px;
}

.user table.history th,
.user .stats span:not(.value),
.user .rating span:not(.value) {
  color: rgb(172, 172, 223);
  font-weight: normal;
}
.user .stats span.value {
  padding: 2px 10px 2px 2px;
  /* font-weight: bold; */
}

.user table.history {
  margin-top: 10px;
}

.user table.history th,
.user table.history td {
  padding: 2px 5px;
}
.user .avatar {
  width: 100px;
  height: 100px;
}

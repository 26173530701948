.mini-Action {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  flex-shrink: 1;
}

.mini-Action .mini-Card {
  min-width: calc(127px * 0.3);
  min-height: calc(176px * 0.3);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

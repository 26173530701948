.player-tag {
  color: grey;
}

.player-tag.active {
  border: dashed;
}

.player-tag.connected {
  color: black;
}

.player-tag .bottom {
  display: grid;
  grid-template-areas: "score status";
}
.player-tag .score::before {
  content: "VP:";
  vertical-align: bottom;
  margin-right: 0.25em;
  display: inline-block;
}
.player-tag .score {
  grid-area: score;
  font-size: small;
  text-align: left;
}

.player-tag .status {
  grid-area: status;
  font-size: small;
  text-align: right;
}

.player-tag .status .invisible {
  visibility: hidden;
}

.player-tag.self {
  font-weight: bold;
}

.player-tag.rubble,
.player-tag.laborer {
  background-color: #feff05;
}

.player-tag.wood,
.player-tag.craftsman {
  background-color: #deb887;
}

.player-tag.brick,
.player-tag.legionary {
  background-color: #fe6247;
}

.player-tag.concrete,
.player-tag.architect {
  background-color: #788899;
}

.player-tag.stone,
.player-tag.merchant {
  background-color: #426ae1;
}

.player-tag.marble,
.player-tag.patron {
  background-color: #dd9fdc;
}

.player-tag.iron,
.player-tag.engineer {
  background-color: #64d4fd;
}

.player-tag.wheat,
.player-tag.farmer {
  background-color: #2cc18e;
}

.player-tag .clientele {
  position: absolute;
  top:0px;
  bottom:0px;
  left:-12px;
  width: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.player-tag .clientele .count.empty {
  visibility: hidden;
}
.player-tag .clientele .count {
  width: 7px;
  height: 10px;
  font-size: 8px;
  border: black solid 1px;
  border-radius: 2px 0 0 2px;
  overflow: clip;
  font-weight: bold;
}

.player-tag .vault {
  position: absolute;
  top:0px;
  bottom:0px;
  right:-13px;
  width: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.player-tag .vault .count.empty {
  visibility: hidden;
}
.player-tag .vault .count {
  width: 7px;
  height: 10px;
  font-size: 8px;
  border: black solid 1px;
  border-radius: 0 2px 2px 0;
  overflow: clip;
  font-weight: bold;
}

.player-tag .vault .count .digit {
  position: relative;
  top: -1px;
}

.player-tag .stockpile {
  position: absolute;
  left:0px;
  bottom:-13px;
  right:0;
  height: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.player-tag .stockpile .count.empty {
  visibility: hidden;
}
.player-tag .stockpile .count {
  width: 17px;
  height: 8px;
  font-size: 9px;
  border: black solid 1px;
  border-radius: 0 0 2px 2px;
  overflow: clip;
  font-weight: bold;
}

.player-tag .stockpile .count .digit {
  position: relative;
  top: -2px;
}

.player-tag .action {
  position: absolute;
  top:-9px;
  left:4px;
  height:12px;
  display: flex;
  flex-direction: row;
}
.player-tag .action .count,
.player-tag .action .one {
  width: 9px;
  height: 16px;
  font-size: 11px;
  border: black solid 1px;
  border-radius: 2px;
  overflow: clip;
  font-weight: bold;
}
.player-tag .action .count .digit {
  position: relative;
  top: 1px;
}

.player-tag .rome-demands {
  position: absolute;
  top:-9px;
  right:4px;
  height:12px;
  display: flex;
  flex-direction: row;
}
.player-tag .rome-demands .count,
.player-tag .rome-demands .one {
  width: 9px;
  height: 16px;
  font-size: 11px;
  border: black solid 1px;
  border-radius: 2px;
  overflow: clip;
  font-weight: bold;
}
.player-tag .rome-demands .count .digit {
  position: relative;
  top: 1px;
}

.player-tag .hand {
  display: flex;
  flex-direction: row;
  place-content: center;
  gap: 1px;
  margin: 3px;
}
.player-tag .hand .count,
.player-tag .hand .one {
  width: 9px;
  height: 16px;
  font-size: 11px;
  border: black solid 1px;
  border-radius: 2px;
  overflow: clip;
  font-weight: bold;
}
.player-tag .hand .count .digit {
  position: relative;
  top: 1px;
}

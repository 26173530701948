.label-js {
  display: inline-block;
  border-radius: 0.1875em;
  color: white;
  background-color: black;
  margin: 0.1875em;
  padding: 0.1875em;
}
.label-js.empty {
  color: rgba(255,255,255,0.4);
  background-color: rgba(0,0,0,0.15);
}

.label-js.thinker {
  background-color: white;
}

.label-js.order {
  background-color: rgb(46, 7, 0);
}

.label-js.jack {
  background-color: rgb(176, 97, 13);
  /* background-color: rgb(146, 93, 13); */
  /* background-color: white; */
}

.label-js.rubble,
.label-js.laborer {
  background-color: #feff05;
  color: black;
}

.label-js.wood,
.label-js.craftsman {
  background-color: #deb887;
  color: black;
}

.label-js.concrete,
.label-js.architect {
  background-color: #788899;
  color: black;
}

.label-js.brick,
.label-js.legionary {
  background-color: #fe6247;
  color: black;
}

.label-js.stone,
.label-js.merchant {
  background-color: #426ae1;
  color: black;
}

.label-js.marble,
.label-js.patron {
  background-color: #dd9fdc;
  color: black;
}

.label-js.iron,
.label-js.engineer {
  background-color: #64d4fd;
  color: black;
}

.label-js.wheat,
.label-js.farmer {
  background-color: #2cc18e;
  color: black;
}

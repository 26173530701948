.mini-OpenHand {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  flex-shrink: 1;
}

.mini-OpenHand .mini-Card {
  min-width: calc(127px * 0.8);
  min-height: calc(176px * 0.8);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.mini-OpenHand .mini-Card .effects {
  position: absolute;
  top: 57%;
  left: 18%;
  right: 6%;
  bottom: 5%;
  color: black;
  font-size: 10px;
  font-family: serif;
  display: flex;
  flex-direction: column;
  justify-content: top;
  margin: 5px;
  border-radius: 4px;
  box-shadow: 0 0 5px white, 0 0 5px white;
  background-color: rgba(211, 211, 211, 1);
  overflow-y: hidden;
  user-select: none;
}
.mini-OpenHand .mini-Card .effects .type {
  font-weight: bold;
}
.mini-OpenHand .mini-Card:hover .effects {
  top: 7%;
  left: 2%;
  right: 2%;
  bottom: 2%;
  justify-content: center;
}

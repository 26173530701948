.player {
  display: flex;
  flex-direction: column;
}
.player-mat {
  display: grid;
  grid-template-rows: 90px auto auto;
  grid-template-areas:
    ". rome-demands ."
    "clientele mat vault"
    ". stockpile .";
  margin: 10px;
}
.player-mat .mat {
  background: #2f4f4f78;
  padding: 5px;
  flex-grow: 1;
  border-radius: 10px;
  color:grey;
  position: relative;
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
  border: solid;
  border-width: 1pt;
  border-color: rgb(165, 165, 165);
}
.player-mat .mat .stockpile-label {
  position: absolute;
  bottom: 0px;
  left: 30px;
  right: 30px;
}
.player-mat .mat .clientele-label {
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  writing-mode: vertical-rl;
}
.player-mat .mat .vault-label {
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
}
.player.connected .player-mat .mat {
  color: white;
}
.player.leader .player-mat .mat {
  border: white solid;
}
.player.self .player-mat .mat {
  background: #3a669278;
}
.player.active .player-mat .mat {
  border: dashed;
}
.stockpile .card.ver2 {
  background-position-y: -145px;
  height: 30px;
}
.incomplete-buildings {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.incomplete-building {
  display: flex;
  flex-direction: column-reverse;
}
.incomplete-building>.card.ver2:not(:first-child) {
  margin-bottom: -140px;
}
.vault .card.ver2:not(.empty),
.vault .card.ver2.player-visibility::after {
  background-position-x: -90px;
  width: 35px;
}
.clientele .card.ver2 {
  width: 35px;
}
.player-mat .rome-demands {
  grid-area: rome-demands;
}
.player-mat .clientele {
  grid-area: clientele;
  align-self: center;
}
.player-mat .clientele .cards {
  align-self: center;
  justify-content: flex-end;
}
.player-mat .stockpile {
  grid-area: stockpile;
  justify-self: center;
  align-self: start;
}
.player-mat .vault {
  grid-area: vault;
  justify-self: start;
  align-self: center;
}
.player-mat .vault .cards {
  grid-area: vault;
  align-self: center;
  justify-content: flex-start;
}
.player-mat .mat {
  grid-area: mat;
  min-width: 300px;
  min-height: 200px;
}
.player .buildings {
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 300px;
}
.player-mat .action {
  position: absolute;
  top: -50px;
  left: 20px;
  z-index: 1;
}
.player:not(.self) .hand .cards {
  flex-wrap: nowrap;
}
.player:not(.self) .hand .cards .card.ver2:not(:first-child) {
  margin-left: -80px;
}
.rome-demands {
  position: relative;
}
.rome-demands .cards {
  position: absolute;
  right: 70px;
  z-index: 1;
  border: 3px red solid;
  box-shadow: 5px;
}

.mini-IncompleteBuildings {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.mini-IncompleteBuildings .row {
  height: 1em;
  display: flex;
}

.mini-IncompleteBuildings .tag {
  width: 7.5em;
  height: 1em;
  background-size: cover;
  background-repeat: no-repeat;
}

.mini-IncompleteBuildings .value {
  font-size: 0.8em;
}

.mini-IncompleteBuildings .tag:not(.last) {
  height: 1.6em;
  margin-bottom: -0.6em;
}

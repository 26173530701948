
.mini-PlayLayout {
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 1vmin);
  color: white;
  gap: 5px;
  max-width: 100%;
  max-height: 100vh;
  box-sizing: border-box;
  align-items: center;
}

.mini-PlayLayout > .bottom-sticky {
  flex: 0 0 auto; /* Take as much space as needed */
}

.mini-PlayLayout > .scroll {
  flex: 1 1 auto; /* Take 100% of what's left */
  overflow-y: scroll; /* Make it scrollable */
}

.mini-PlayLayout > * {
  max-width: 100%;
  box-sizing: border-box;
}

.mini-PlayLayout > .scroll > .inner-scroll {
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 1vmin);
  gap: 5px;
  max-width: 100%;
  box-sizing: border-box;
  align-items: center;
}

.mini-PlayLayout > .scroll > .inner-scroll > * {
  max-width: 100%;
  box-sizing: border-box;
}

.mini-PlayLayout > .scroll > .inner-scroll > * {
  max-width: 100%;
  box-sizing: border-box;
}

.mini-PlayLayout .sites-and-decks {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.mini-PlayLayout .options {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.mini-PlayLayout .options > .option {
  margin: 5px;
  font-size: 20px;
}

.mini-PlayLayout .options > .option.craftsman,
.mini-PlayLayout .options > .option.wood {
  background-color: hsl(9deg 72% 24%);
  color: hsl(0deg 0% 87%);
  border: hsl(9deg 68% 34%) outset 2px;
}

.mini-PlayLayout .options > .option.architect,
.mini-PlayLayout .options > .option.concrete {
  background-color: hsl(312deg 3% 31%);
  color: hsl(0deg 0% 89%);
  border: hsl(312deg 3% 38%) outset 2px;
}

.mini-PlayLayout .options > .option.laborer,
.mini-PlayLayout .options > .option.rubble {
  background-color: hsl(49deg 99% 30%);
  color: hsl(0deg 0% 97%);
  border: hsl(49deg 99% 38%) outset 2px;
}

.mini-PlayLayout .options > .option.merchant,
.mini-PlayLayout .options > .option.stone {
  background-color: hsl(194deg 100% 31%);
  color: hsl(0deg 0% 94%);
  border: hsl(194deg 100% 38%) outset 2px;
}

.mini-PlayLayout .options > .option.patron,
.mini-PlayLayout .options > .option.marble {
  background-color: hsl(313deg 53% 36%);
  color: hsl(0deg 0% 88%);
  border: hsl(313deg 53% 43%) outset 2px;
}

.mini-PlayLayout .options > .option.legionary,
.mini-PlayLayout .options > .option.brick {
  background-color: hsl(8deg 86% 34%);
  color: hsl(0deg 0% 88%);
  border: hsl(8deg 86% 43%) outset 2px;
}

.mini-PlayLayout .options > .option.jack {
  background-color: hsl(39deg 79% 38%);
  color: hsl(0deg 0% 93%);
  border: hsl(39deg 79% 43%) outset 2px;
}

.mini-PlayLayout .error {
  color: red;
}

.mini-PlayLayout .bottom-sticky {
  display: flex;
  flex-direction: column;
  gap: 5px;
  bottom: 0;
  align-self: stretch;
}

.mini-PlayLayout .LogPanel {
  display: flex;
  flex-direction: column;
  min-height: 100px;
  max-height: 100px;
}

.mini-PlayLayout .LogPanel > * {
  flex-grow: 1;
}

/* Options pop-ups */

.mini-PlayLayout .option-popup {
  text-align: center;
}

.mini-PlayLayout .option-popup .title {
  font-weight: bold;
  margin: 3px;
}

.mini-PlayLayout .option-popup .description {
  margin-bottom: 10px;
}

.mini-PlayLayout .option-popup .option-popup div {
  margin: 2px;
}

a.player-name {
  text-decoration: none;
}
a.player-name.human-user {
  min-width: 10px;
  min-height: 10px;
  display: inline-block;
}
a.player-name.human-user .label {
  color: rgb(172, 172, 223);
  font-weight: normal;
  font-size: 70%;
}
a.player-name.human-user.guest {
  opacity: 0.8;
}
a.player-name:not(.human-user) {
  opacity: 0.7;
}

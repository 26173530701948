.card-images.academy {
  background-image: url("./card/images/ver2/academy.png");
}
.card-images.amphitheatre {
  background-image: url("./card/images/ver2/amphitheatre.png");
}
.card-images.aqueduct {
  background-image: url("./card/images/ver2/aqueduct.png");
}
.card-images.archway {
  background-image: url("./card/images/ver2/archway.png");
}
.card-images.atrium {
  background-image: url("./card/images/ver2/atrium.png");
}
.card-images.bar {
  background-image: url("./card/images/ver2/bar.png");
}
.card-images.basilica {
  background-image: url("./card/images/ver2/basilica.png");
}
.card-images.basilica-arbitrium {
  background-image: url("./card/images/ver2/basilica-arbitrium.png");
}
.card-images.bath {
  background-image: url("./card/images/ver2/bath.png");
}
.card-images.brick.site {
  background-image: url("./card/images/ver2/brick-site.png");
}
.card-images.bridge {
  background-image: url("./card/images/ver2/bridge.png");
}
.card-images.catacomb {
  background-image: url("./card/images/ver2/catacomb.png");
}
.card-images.circus {
  background-image: url("./card/images/ver2/circus.png");
}
.card-images.circus-maximus {
  background-image: url("./card/images/ver2/circus-maximus.png");
}
.card-images.coliseum {
  background-image: url("./card/images/ver2/coliseum.png");
}
.card-images.concrete.site {
  background-image: url("./card/images/ver2/concrete-site.png");
}
.card-images.crane {
  background-image: url("./card/images/ver2/crane.png");
}
.card-images.dock {
  background-image: url("./card/images/ver2/dock.png");
}
.card-images.domus-aurea {
  background-image: url("./card/images/ver2/domus-aurea.png");
}
.card-images.forum {
  background-image: url("./card/images/ver2/forum.png");
}
.card-images.forum-romanum {
  background-image: url("./card/images/ver2/forum-romanum.png");
}
.card-images.foundry {
  background-image: url("./card/images/ver2/foundry.png");
}
.card-images.fountain {
  background-image: url("./card/images/ver2/fountain.png");
}
.card-images.garden {
  background-image: url("./card/images/ver2/garden.png");
}
.card-images.gate {
  background-image: url("./card/images/ver2/gate.png");
}
.card-images.insula {
  background-image: url("./card/images/ver2/insula.png");
}
.card-images.jack {
  background-image: url("./card/images/ver2/jack0.png");
}
.card-images.latrine {
  background-image: url("./card/images/ver2/latrine.png");
}
.card-images.ludus-magna {
  background-image: url("./card/images/ver2/ludus-magna.png");
}
.card-images.marble.site {
  background-image: url("./card/images/ver2/marble-site.png");
}
.card-images.market {
  background-image: url("./card/images/ver2/market.png");
}
.card-images.order {
  background-image: url("./card/images/ver2/order.png");
}
.card-images.palace {
  background-image: url("./card/images/ver2/palace.png");
}
.card-images.palisade {
  background-image: url("./card/images/ver2/palisade.png");
}
.card-images.prison {
  background-image: url("./card/images/ver2/prison.png");
}
.card-images.road {
  background-image: url("./card/images/ver2/road.png");
}
.card-images.rubble.site {
  background-image: url("./card/images/ver2/rubble-site.png");
}
.card-images.school {
  background-image: url("./card/images/ver2/school.png");
}
.card-images.scriptorium {
  background-image: url("./card/images/ver2/scriptorium.png");
}
.card-images.senate {
  background-image: url("./card/images/ver2/senate.png");
}
.card-images.sewer {
  background-image: url("./card/images/ver2/sewer.png");
}
.card-images.shrine {
  background-image: url("./card/images/ver2/shrine.png");
}
.card-images.stairway {
  background-image: url("./card/images/ver2/stairway.png");
}
.card-images.statue {
  background-image: url("./card/images/ver2/statue.png");
}
.card-images.stone.site {
  background-image: url("./card/images/ver2/stone-site.png");
}
.card-images.storeroom {
  background-image: url("./card/images/ver2/storeroom.png");
}
.card-images.temple {
  background-image: url("./card/images/ver2/temple.png");
}
.card-images.tower {
  background-image: url("./card/images/ver2/tower.png");
}
.card-images.tribunal {
  background-image: url("./card/images/ver2/tribunal.png");
}
.card-images.villa {
  background-image: url("./card/images/ver2/villa.png");
}
.card-images.vomitorium {
  background-image: url("./card/images/ver2/vomitorium.png");
}
.card-images.wall {
  background-image: url("./card/images/ver2/wall.png");
}
.card-images.wood.site {
  background-image: url("./card/images/ver2/wood-site.png");
}

.LogPanel .log {
  overflow-y: auto;
  scroll-behavior: smooth;
  font-size: 10pt;
  background: rgba(0,0,0,0.3);
  margin: 10px;
  max-width: 100%;
  max-height: 100%;
  padding-bottom: 10px;
}

.LogPanel .log > p {
  margin: 2px;
}

.LogPanel .log .LogEntry.old {
  opacity: 0.7;
}
.LogPanel .log .log-separator {
  height: 1px;
  background-color: grey;
  width: 90%;
  margin-left: 5%;
  margin-top: 5px;
  margin-bottom: 5px;
}

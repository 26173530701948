.mini-Pool {
  display: grid;
  justify-content: center;
}

.mini-Pool .title {
  text-transform: capitalize;
}

.mini-Pool .pool {
  align-self: center;
}

.mini-Pool .title {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 72px;
  min-width: 53px;
}
